.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.factset, .oracle, .zone, .portfolio {
    height: 100vh; /* Adjust the height as needed */
    width: 60vw !important;
}

.workHistory {
    height: 100vh;
    display: flex;
    justify-content: center; /* horizontally center */
    align-items: center; /* vertically center */
    flex-direction: column;
    font-size: 1vw;
    background: linear-gradient(90deg, rgba(225,225,225,0.8) 0%, rgba(190,190,190,0.8) 35%, rgba(150,150,150,0.8) 100%);
}

.self .workHistory {
    background: none;
    justify-content: initial; /* horizontally center */
}

.portfolio .inner {
    background-color: rgb(14, 33, 33, 0.80);
    color: aliceblue;
    min-height: 100vh;
}

.portfolio .companyName {
    color: aliceblue;
}

.project {
    margin-top: 20px !important;
}

.project img {
    width: 100%;
    border-radius: 5px;
    border: 1px solid white;
}

.project .description-left, .project .description-right {
    text-align: right;
    font-size: 1vw;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.project .description-right {
    text-align: left;
}

.project .description .btn {
    font-size: 1vw;
}

.project .description .title {
    font-size: 3vw;
}

.bgImage {
    background-size: cover;
    display: block;
    position: absolute;
    left: 0;
    height: 100vh;
    filter: grayscale(50%) blur(4px);
    z-index: -1;
}

.factset > .bgImage {
    top: 100vh;
    background-image: url(https://g.foolcdn.com/editorial/images/502410/office-with-people-at-desks_gettyimages-869285818.jpg);
}

.oracle > .bgImage {
    top: 200vh;
    background-image: url(https://images.pexels.com/photos/380768/pexels-photo-380768.jpeg?cs=srgb&dl=office-table-architecture-380768.jpg&fm=jpg);
}

.zone > .bgImage {
    top: 300vh;
    background-image: url(https://c.pxhere.com/photos/9e/dd/american_coffee_colleagues_cups_digital_device_discussion_drinks_facial_expression-1531599.jpg!d);
}

.portfolio > .bgImage {
    background-size: auto;
    background-image: url(https://img.freepik.com/premium-vector/rough-black-white-texture-distressed-grunge-background_508630-927.jpg);
    background-color: white;
    filter: grayscale(50%);
    min-height: 100vh;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
}

.companyName {
    font-size: 3vw;
    font-family: Cambria, Cochin, Georgia, Times, Times New Roman, serif;
}

.downButton {
    height: 5vh;
    opacity: 70%;
}

.tech {
    text-align: center;
    font-family: Courier New, Courier, monospace;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.factset .tech {
    background: linear-gradient(90deg, rgba(0,128,255, 0.8) 0%, rgba(77,166,255,0.8) 35%, rgba(0,90,179, 0.8) 100%);
}

.oracle .tech {
    background: linear-gradient(90deg, rgba(199, 70, 52, 1) 0%, rgba(199, 70, 52, 0.8) 35%, rgba(199, 70, 52, 1) 100%);
    color: white;
}

.zone .tech {
    background: linear-gradient(90deg, rgba(255, 75, 63, 1) 0%, rgba(14, 34, 60, 1) 40%);
    color: white;
}

.portfolio .tech {
    margin-bottom: 0;
}